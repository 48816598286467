import hamburgerIcon from "assets/core/hamburger.svg";
import { i18n } from "i18n/localisation";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import profilePic from "../../assets/core/default_profile.svg";
import eyeIcon from "../../assets/core/eye.svg";
import leftArrow from "../../assets/core/left_chevron.svg";
import rightArrow from "../../assets/core/right_chevron.svg";
import shareIcon from "../../assets/core/share.svg";
import Button from "../ui/Button/Button";

const MyPropertiesHeader = () => {
  const navigate = useNavigate();
  const { title, userData, isPublished } = useSelector((state) => ({
    title: state.myProperties.title,
    userData: state.user.userData,
    isPublished: state.myProperties.isPublished,
  }));

  return (
    <div className="upload-page_header">
      <div className="upload-page_header_wrapper">
        <div className="upload-page_header_title-subtitle">
          <div className="upload-page_header_title">
            <img
              src={leftArrow}
              alt="back"
              onClick={() => navigate("/my-properties")}
              className="cursor-pointer"
            />
            <span>{title}</span>
          </div>
          <div className="upload-page_header_subtitle">
            <div className="profile-image">
              <img
                src={userData.agencyDetails?.picture || profilePic}
                alt="Profile icon"
              />
            </div>
            <p>
              {userData.personalDetails?.firstName +
                " " +
                userData.personalDetails?.lastName}
            </p>
            <img src={rightArrow} alt="right arrow" />
            <div className="h-[26px] w-[1px] bg-[#E1E4EB]" />
            <div
              className={`flex h-[33px] w-[125px] items-center justify-center gap-[8px] rounded-full ${isPublished ? "bg-[#12B76A]/10" : "bg-[#F0F0F0E5]"}`}
            >
              <span
                className={`h-[8px] w-[8px] rounded-full ${isPublished ? "bg-[#12B76A]" : "bg-[#222222]"}`}
              />
              <p
                className={`text-[14px] ${isPublished ? "text-[#12B76A]" : "text-[#222222]"}`}
              >
                {isPublished ? i18n("Published") : i18n("Unpublished")}
              </p>
            </div>
          </div>
        </div>
        <div className="upload-page_header_viewer-share-content">
          <Button onClick={() => {}} variant="secondary">
            <img src={eyeIcon} alt="share icon" />
            <span className="ml-[8px]">{i18n("See as a Viewer")}</span>
          </Button>
          <Button onClick={() => {}} variant="secondary">
            <img src={shareIcon} alt="share icon" />
            <span className="ml-[8px]">{i18n("Share Property")}</span>
          </Button>
          <div>
            <img src={hamburgerIcon} alt="hambuguer icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyPropertiesHeader;
