import { Font, StyleSheet } from "@react-pdf/renderer";

export const chunkSubstr = (str, size) => {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size);
  }

  return chunks;
};

Font.registerHyphenationCallback((word) => {
  if (word.length > 16) {
    return chunkSubstr(word, 14);
  } else {
    return [word];
  }
});

const PDF_STYLES = StyleSheet.create({
  page: {
    position: "relative",
    flexDirection: "row",
    backgroundColor: "white",
    padding: 20,
    fontFamily: "Inter",
    paddingTop: 40,
  },
  spacer: {
    marginTop: 22,
  },
  title: {
    fontSize: 50,
    color: "#1E0D05",
    fontWeight: 700,
    flexWrap: "wrap",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  paddedSection: {
    margin: 20,
    padding: 20,
    flexGrow: 1,
  },
  heading: {
    marginTop: 24,
    fontSize: 20,
    fontWeight: 600,
    color: "#222",
  },
  heading2: {
    fontSize: 16,
    fontWeight: 600,
    color: "#222",
    flexDirection: "row",
    flexWrap: "wrap",
    overflowWrap: "break-word",
  },
  subHeading: {
    fontSize: 14,
    color: "#717171",
    marginBottom: 4,
  },
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: "#E1E4EB",
    marginBottom: 24,
    marginTop: 24,
  },
  propertyImage: {
    width: "100%",
    height: "auto",
    maxHeight: 400,
    borderRadius: 8,
    objectFit: "cover",
  },
  agencyLogo: {
    width: 40,
    height: 40,
    position: "absolute",
    bottom: 0,
    left: 0,
    borderRadius: "50%",
    objectFit: "cover",
  },
  row: {
    marginTop: 8,
    flexDirection: "row",
    display: "flex",
  },
  wordWrapTextContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    overflowWrap: "break-word",
  },
  col: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  valuationPriceText: {
    marginTop: 8,
    fontSize: 24,
    color: "#222",
    fontWeight: 700,
  },
  title2: {
    fontSize: 22,
    color: "#222",
    fontWeight: "bold",
    flexDirection: "row",
    flexWrap: "wrap",
    overflowWrap: "break-word",
  },
  rangePriceText: {
    marginTop: 14,
    fontSize: 16,
    color: "#222",
  },
  verticalDivider: {
    marginLeft: 8,
    marginRight: 8,
    backgroundColor: "#BABABA",
    width: 1,
    height: 12,
    position: "relative",
    top: 2,
  },
  text: {
    fontSize: 12,
    color: "#222",
  },
  faintText: {
    fontSize: 12,
    color: "#717171",
  },
  smallSpacer: {
    marginTop: 4,
  },
  midSpacer: {
    marginTop: 8,
  },
  spacer2: {
    marginTop: 14,
  },
  greyBox: {
    padding: 16,
    backgroundColor: "#E1E4EB",
    borderRadius: 8,
    paddingBottom: 8,
  },
  text2: {
    fontSize: 11,
    color: "#717171",
    lineHeight: 1.75,
  },
  circle: {
    width: 20,
    height: 20,
    backgroundColor: "#ee3943",
    borderRadius: "50%",
    border: "1px solid #000000",
    display: "flex",
    marginRight: 8,
  },
  numText: {
    color: "#FFFFFF",
    fontSize: 10,
    textAlign: "center",
    flex: 1,
    marginTop: 3,
  },
  imageContainer: {
    width: "100%",
    height: "auto",
    maxHeight: 325,
    borderRadius: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: 8,
  },
  imageQuadrantContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    // justifyContent: "space-between",
    flex: 1,
  },
  imageQuadrantImageContainer: {
    width: "47%",
    marginBottom: "3%", // Margin between images
    marginRight: "3%",
    borderRadius: 8,
    maxHeight: 155,
    padding: 0,
  },
  quandrantImage: {
    width: "100%",
    height: "100%",
    borderRadius: 8,
    objectFit: "cover",
  },
  refText: {
    position: "absolute",
    top: 30,
    left: 40,
    fontSize: 10,
    width: 200,
  },
  historyTitle: {
    fontSize: 30,
    color: "#1E0D05",
    fontWeight: 700,
    marginBottom: 10,
  },
  historyLogo: {
    marginTop: 10,
    width: 70,
    height: 70,
    borderRadius: 50,
    marginRight: 10,
  },
  historyInfoContent: {
    marginLeft: 8,
    marginTop: 4,
  },
  verticalLine: {
    height: 30,
    width: 1,
    marginLeft: 27,
    marginTop: 5,
    borderRadius: 50,
    backgroundColor: "#f2f4f8",
  },
  historyIconContainer: {
    width: 55,
    height: 55,
    borderRadius: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderColor: "#717171",
    borderStyle: "solid",
    borderWidth: 2,
  },
  historyIcon: {
    width: 40,
    height: 40,
    borderRadius: 50,
  },
  upPercentage: {
    color: "green",
  },
  downPercentage: {
    color: "red",
  },
  priceLow: {
    height: 15,
    color: "red",
  },
  priceHigh: {
    height: 15,
    color: "green",
  },
  historyDate: {
    fontSize: 18,
  },
  historyPrice: {
    fontSize: 18,
    fontWeight: 700,
  },
});

export { PDF_STYLES };
