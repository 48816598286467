import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchUserData, saveUserData } from "actions/userActions";
import { i18n } from "i18n/localisation";
import Button from "components/ui/Button/Button";
import PrimaryInput from "components/core/PrimaryInput";
import Dropdown from "components/core/Dropdown";
import { toast } from "sonner";
import "./onboardingPage.scss";

const USER_TYPES = [
  {
    label: "Agent",
    value: "agent",
  },
  {
    label: "Agency Owner/Director",
    value: "agency_owner",
  },
  {
    label: "Investor",
    value: "investor",
  },
  {
    label: "Data Analyst",
    value: "data_analyst",
  },
  {
    label: "Individual",
    value: "individual",
  },
];

const COMPANY_SIZES = [
  {
    label: "1-5",
    value: "1-5",
  },
  {
    label: "6-10",
    value: "6-10",
  },
  {
    label: "11-50",
    value: "11-50",
  },
  {
    label: "51-200",
    value: "51-200",
  },
  {
    label: "201-500",
    value: "201-500",
  },
  {
    label: "501-1000",
    value: "501-1000",
  },
  {
    label: "1000+",
    value: "1000+",
  },
];

const INTERESTS = [
  {
    label: "Search for properties",
    value: "search_for_properties",
  },
  {
    label: "Valuate properties",
    value: "valuate_properties",
  },
  {
    label: "Search for leads",
    value: "search_for_leads",
  },
  {
    label: "Connect with other professionals",
    value: "connect_with_other_professionals",
  },
  {
    label: "Customer relationship management",
    value: "customer_relationship_management",
  },
  {
    label: "API access",
    value: "api_access",
  },
  {
    label: "Market analysis",
    value: "market_analysis",
  },
  {
    label: "Other",
    value: "other",
  },
];

const OnboardingPage = ({ userData }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userType, setUserType] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [interests, setInterests] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setFirstName(userData.personalDetails.firstName ?? "");
    setLastName(userData.personalDetails.lastName ?? "");
    setUserType(userData.userType ?? "");
    setCompanySize(userData.miscDetails?.companySize ?? "");
    setInterests(userData.miscDetails?.interests ?? []);
    setPhoneNumber(userData.personalDetails.phone ?? "");
  }, [userData.personalDetails]);

  const validateForm = () => {
    const formErrors = {};
    if (!firstName.trim())
      formErrors.firstName = i18n("First name is required");
    if (!lastName.trim()) formErrors.lastName = i18n("Last name is required");
    if (!userType) formErrors.userType = i18n("Please select your role");
    return formErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    setLoading(true);

    try {
      dispatch(
        saveUserData(
          {
            personalDetails: {
              firstName: firstName.trim(),
              lastName: lastName.trim(),
              phone: phoneNumber.trim(),
            },
            userType: userType,
            miscDetails: {
              companySize,
              interests,
            },
          },
          () => {
            dispatch(
              fetchUserData(() => {
                navigate("/");
              }),
            );
          },
        ),
      );
    } catch (error) {
      console.error("Error saving user data:", error);
      toast.error(i18n("Failed to update profile"));
    }
  };

  return (
    <div className="onboarding-page flex min-h-screen flex-col items-center justify-center bg-gray-100 p-4">
      <div className="w-full max-w-md rounded-lg bg-white p-8 shadow-md">
        <h1 className="mb-6 text-center text-2xl font-bold">
          {i18n("Welcome to")} <span className="text-cta">iRealty</span>
        </h1>

        <p className="mb-8 text-center text-gray-600">
          {i18n("Please tell us a bit about yourself")}
        </p>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="mb-2 block text-sm font-medium text-gray-700">
              {i18n("First Name")} *
            </label>
            <PrimaryInput
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder={i18n("Enter your first name")}
            />
          </div>

          <div>
            <label className="mb-2 block text-sm font-medium text-gray-700">
              {i18n("Last Name")} *
            </label>
            <PrimaryInput
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder={i18n("Enter your last name")}
            />
          </div>

          <div>
            <label className="mb-2 block text-sm font-medium text-gray-700">
              {i18n("I am a")} *
            </label>
            <Dropdown
              className="w-full"
              items={USER_TYPES.map((type) => ({
                label: i18n(type.label),
                value: type.value,
                onSelect: () => {
                  setUserType(type.value);
                  setErrors({ ...errors, userType: null });
                },
              }))}
              placeholder={userType ? null : i18n("Select your role")}
              label={
                userType
                  ? USER_TYPES.find((t) => t.value === userType)?.label
                  : ""
              }
            />
          </div>

          <div>
            <label className="mb-2 block text-sm font-medium text-gray-700">
              {i18n("Size of company")} {i18n("(optional)")}
            </label>
            <Dropdown
              className="w-full"
              items={COMPANY_SIZES.map((type) => ({
                label: i18n(type.label),
                value: type.value,
                onSelect: () => {
                  setCompanySize(type.value);
                  setErrors({ ...errors, companySize: null });
                },
              }))}
              placeholder={
                companySize ? null : i18n("Select your company size")
              }
              label={
                companySize
                  ? COMPANY_SIZES.find((t) => t.value === companySize)?.label
                  : ""
              }
              isInverted={true}
            />
          </div>

          <div>
            <label className="mb-2 block text-sm font-medium text-gray-700">
              {i18n("I am interested in")} {i18n("(optional)")}
            </label>
            <Dropdown
              className="w-full"
              items={INTERESTS.map((type) => ({
                label: i18n(type.label),
                value: type.value,
                id: type.value,
                onSelect: () => {
                  const newInterests = interests.includes(type.value)
                    ? interests.filter((i) => i !== type.value)
                    : [...interests, type.value];
                  setInterests(newInterests);
                  setErrors({ ...errors, interests: null });
                },
              }))}
              placeholder={
                interests.length ? null : i18n("Select your interests")
              }
              label={
                interests.length
                  ? interests
                      .map((i) =>
                        i18n(INTERESTS.find((t) => t.value === i)?.label),
                      )
                      .join(", ")
                  : ""
              }
              selectedIds={interests}
              isInverted={true}
              isMulti={true}
            />
          </div>

          {Object.values(errors).some((error) => error) && (
            <div className="text-sm text-red-600">
              {Object.values(errors)
                .filter((error) => error)
                .map((error, index) => (
                  <div key={index}>{error}</div>
                ))}
            </div>
          )}

          <Button
            loading={loading}
            onClick={handleSubmit}
            className="onboarding-page_submit mt-8 w-full justify-center text-center"
          >
            {i18n("Continue")}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default connect((state) => ({
  userData: state.user.userData,
}))(OnboardingPage);
