import { memo } from "react";
import "./LoadingSpinner.scss";

const LoadingSpinner = memo(({ overlay = true, size = "medium" }) => {
  return (
    <div className={`spinner-container ${overlay ? "overlay" : ""}`}>
      <div className={`spinner ${size}`}>
        <div className="spinner-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
});

LoadingSpinner.displayName = "LoadingSpinner";

export default LoadingSpinner;
