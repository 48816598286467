import { Storage } from "aws-amplify";
import { GOOGLE_MAPS_API_KEY, MEDIA_BUCKET_URL } from "config/constants";
import store from "../store";
import { getCentroid } from "./helpers";

var PUBLIC_MEDIA_BASE_URL =
  "https://irealty-media-dev.s3.eu-west-2.amazonaws.com/public/";

if (process.env.REACT_APP_NODE_ENV === "production") {
  PUBLIC_MEDIA_BASE_URL =
    "https://irealty-media-prod.s3.eu-west-2.amazonaws.com/public/";
}

// Get the MIME type of the file
export const getMimeType = (fileType) => {
  const mimeTypes = {
    jpeg: "image/jpeg",
    jpg: "image/jpeg",
    png: "image/png",
    gif: "image/gif",
    txt: "text/plain",
    csv: "text/csv",
    html: "text/html",
    pdf: "application/pdf",
    zip: "application/zip",
    tar: "application/x-tar",
    bin: "application/octet-stream",
    mp4: "video/mp4",
  };

  return mimeTypes[fileType.toLowerCase()] || "application/octet-stream";
};

export function getUrlForKey(key) {
  return MEDIA_BUCKET_URL + "public/" + key;
}

// Upload the file to the S3 bucket
export const uploadFile = (file, fileName, mimeType) =>
  new Promise((resolve, reject) => {
    const options = {
      level: "public", // access level
      contentType: mimeType, // mime type
      crossOrigin: "anonymous",
    };

    const state = store.getState();
    const { user } = state.auth;

    if (!user) {
      return reject("User is not logged in");
    }

    // Uploading files to the bucket by user id
    Storage.put(`${user.sub}/${fileName}`, file, options)
      .then((result) => {
        console.log(`File uploaded successfully. ${result.key}`);
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });

// Convert base64 string to Blob object
function base64ToBlob(base64, mimeType) {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: mimeType });
}

// uploads a map of the current search to S3 and returns the servable url of the image
export function generateThumbnailForSearch(searchId, polygons) {
  let center = getCentroid(polygons[0].geometry);
  let location = `${center.latitude},${center.longitude}`;
  const imageUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${location}&zoom=14&size=700x364&key=${GOOGLE_MAPS_API_KEY}`;

  // get image from imageUrl into file
  return new Promise(async (resolve, reject) => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    // upload the file and resolve with the key
    uploadFile(blob, "search/" + searchId + ".png", "image/png")
      .then((result) => {
        resolve(PUBLIC_MEDIA_BASE_URL + result.key);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export default { PUBLIC_MEDIA_BASE_URL };
