import {
  setCatastroProperty,
  setCatastroReference,
} from "actions/myPropertiesActions";
import { getCatastralByRefDirect } from "api/cma";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { catastroToCmaProperty, searchMapForReferences } from "utils/map";
import searchIcon from "../../assets/search/search_icon.svg";
import { i18n } from "../../i18n/localisation";
import "./myProperties.scss";

const UploadPropertySearchBar = (props) => {
  const dispatch = useDispatch();

  const catastroReference = useSelector(
    (state) => state.myProperties.catastroReference,
  );
  const selectedPlot = useSelector((state) => state.plots.selectedPlot);

  useEffect(() => {
    if (catastroReference) {
      searchMapForReferences(catastroReference, "myProperties");
    }
  }, []);

  useEffect(() => {
    if (selectedPlot) {
      dispatch(setCatastroReference(selectedPlot.refcat));

      const findCatastralByRef = async (ref) => {
        const promise = getCatastralByRefDirect(ref);
        promise.then((data) => {
          const property = catastroToCmaProperty(
            data,
            selectedPlot.refcat,
            selectedPlot.center_y,
            selectedPlot.center_x,
            selectedPlot,
          );

          dispatch(setCatastroProperty(property));
        });
      };

      findCatastralByRef(selectedPlot.refcat);
    }
  }, [selectedPlot]);

  const handleSearch = () => {
    if (catastroReference) {
      searchMapForReferences(catastroReference, "myProperties");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div className="upload-property-search-bar_container">
      <div className="upload-property-search-bar_input-content">
        <input
          id="search-input"
          value={catastroReference}
          type="text"
          placeholder={i18n("Search Catastral Reference")}
          onChange={(e) => dispatch(setCatastroReference(e.target.value))}
          onKeyDown={handleKeyDown}
        />
      </div>
      <div className="upload-property-search-bar_divider"></div>
      <div
        className="upload-property-search-bar_container_search-icon-content"
        onClick={handleSearch}
      >
        <img src={searchIcon} alt="search icon" />
      </div>
    </div>
  );
};

export default UploadPropertySearchBar;
