import plusIcon from "assets/core/plus.svg";
import PropTypes from "prop-types";
import { useRef } from "react";
import Button from "../../ui/Button/Button";
import IconComponent from "../IconComponent";
import { ICON_CONFIG, NO_MEDIA_CONTAINER } from "../uploadPropertyPageConfig";

const MediaEmptyTile = ({ tab, size, onFileSelect }) => {
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event) => {
    if (typeof onFileSelect !== "function") {
      console.error("onFileSelect prop is not a function");
      return;
    }

    const files = Array.from(event.target.files);
    if (files.length > 0) {
      onFileSelect(files);
      event.target.value = "";
    }
  };

  const getAcceptedFileTypes = () => {
    switch (tab?.key) {
      case "photos":
      case "floorPlans":
      case "virtualTours":
        return "image/jpeg,image/png";
      case "videos":
        return "video/mp4";
      default:
        return "";
    }
  };

  return (
    <div
      className={`${"media-empty-tile"} ${size === "small" ? "h-[187px]" : "h-[336px]"}`}
    >
      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        accept={getAcceptedFileTypes()}
        multiple
        onChange={handleFileChange}
      />
      <div className="media-empty-tile_wrapper">
        {size === "standard" && (
          <div className="h-[40px] w-[40px]">
            <IconComponent
              icon={tab.icon}
              color={"#717171"}
              parameter={ICON_CONFIG[tab.key].parameter}
            />
          </div>
        )}
        <Button
          variant="secondary"
          className="flex h-[44px] justify-around gap-[8px]"
          onClick={handleClick}
        >
          <img src={plusIcon} alt="add media" />
          <span>{NO_MEDIA_CONTAINER[tab?.key]?.label}</span>
        </Button>
        <p>{NO_MEDIA_CONTAINER[tab?.key]?.text}</p>
      </div>
    </div>
  );
};

MediaEmptyTile.propTypes = {
  tab: PropTypes.shape({
    key: PropTypes.string.isRequired,
    icon: PropTypes.elementType.isRequired,
  }).isRequired,
  size: PropTypes.oneOf(["small", "standard"]),
  onFileSelect: PropTypes.func.isRequired,
};

MediaEmptyTile.defaultProps = {
  size: "standard",
  onFileSelect: () => console.warn("onFileSelect prop not provided"),
};

export default MediaEmptyTile;
