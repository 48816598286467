import fireApiRequest from "./core";

var FETCH_ANALYTICS_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/fetch_analytics";

if (process.env.REACT_APP_NODE_ENV === "production") {
  FETCH_ANALYTICS_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/fetch_analytics";
}

/**
 * Fetches analytics by type and area id
 * @param {string} type
 * @param {string} areaId
 * @returns {Promise<Array<AnalyticsItem>>}
 */
export async function fetchAnalyticsRequest(type, areaId) {
  try {
    let data = await fireApiRequest(FETCH_ANALYTICS_ENDPOINT, {
      method: "POST",
      body: JSON.stringify({
        type,
        area_id: areaId,
      }),
    });

    return data.results;
  } catch (e) {
    console.log(e);
  }
}
