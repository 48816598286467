import { pdf } from "@react-pdf/renderer";
import Dropdown from "components/core/Dropdown";
import { GOOGLE_MAPS_API_KEY, SUPPORTED_LANGUAGES } from "config/constants";
import { i18n } from "i18n/localisation";
import { sendAnalyticsEvent } from "lib/analytics";
import { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getDataUrl, roundToNearest1000, unwatermarkImg } from "utils/helpers";
import Button from "../ui/Button/Button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import { CmaDocument } from "./CmaPDF";
import ValuationSlider from "./CmaValuationSlider";
import CmaPDFProvider from "./CmaPDF";

const CmaModal = ({
  isValutionModalOpen,
  valuationValue,
  property,
  closeModal,
  district,
  municipality,
  province,
  studyAreas,
  rooms,
  bathrooms,
  features,
  agencyDetails,
  personalDetails,
  user,
  ...props
}) => {
  const roundedOriginalValuationValue = roundToNearest1000(valuationValue);
  const [valuation, setValuation] = useState(roundedOriginalValuationValue);
  const [clientName, setClientName] = useState("");
  const [clientSurname, setClientSurname] = useState("");
  const [propertyName, setPropertyName] = useState("");
  const [customImage, setCustomImage] = useState(null);
  const [renderCount, setRenderCount] = useState(0);
  const [language, setLanguage] = useState(SUPPORTED_LANGUAGES[0]);

  let upperRange = Math.round(roundedOriginalValuationValue * 1.05);
  let lowerRange = Math.round(roundedOriginalValuationValue * 0.95);
  let currentValuation = roundedOriginalValuationValue;

  if (valuation) {
    upperRange = Math.round(valuation * 1.05);
    lowerRange = Math.round(valuation * 0.95);
    currentValuation = valuation;
  }

  let agencyLogoUrl = "https://dashboard.irealty.app/logo.png";
  if (agencyDetails && agencyDetails.picture) {
    agencyLogoUrl = agencyDetails.picture;
  }

  // Resolve property image URL
  let propertyImageUrl = null;
  const isInternalValuation = !property.isCatastro;

  if (property.isCatastro) {
    const apiKey = GOOGLE_MAPS_API_KEY;
    const latitude = property.latitude;
    const longitude = property.longitude;
    const zoom = 20;
    const size = "1600x400";
    const mapType = "hybrid";
    const scale = 2;
    propertyImageUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=${zoom}&size=${size}&maptype=${mapType}&key=${apiKey}&scale=${scale}`;
  } else if (property.images && property.images.length > 0) {
    propertyImageUrl = unwatermarkImg(property.images[0].url);
  }

  const location = `${property.latitude},${property.longitude}`;
  const locationUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${location}&zoom=15&size=1600x400&key=${GOOGLE_MAPS_API_KEY}&scale=2&markers=color:0xef5533%7C${location}`;

  const onCustomImageInputChange = async (e) => {
    const file = e.target.files[0];
    try {
      const dataUrl = await getDataUrl(file);
      setCustomImage(dataUrl);
      setRenderCount(renderCount + 1);
    } catch (error) {
      setCustomImage(null);
      console.error("Error reading file:", error);
    }
  };

  const [pdfKey, setPdfKey] = useState(0);

  // State to control rendering of CmaPDFProvider
  const [showMergedPdf, setShowMergedPdf] = useState(false);

  const openPdf = () => {
    setShowMergedPdf(false);
    setPdfKey((prevKey) => prevKey + 1);
    setShowMergedPdf(true);

    sendAnalyticsEvent("CMA Modal Click", {
      type: "generate pdf",
      details: property,
    });
  };

  return (
    <Dialog
      open={isValutionModalOpen}
      onOpenChange={(open) => {
        closeModal();
        setShowMergedPdf(false); // Reset merged PDF link when modal closes
      }}
    >
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{i18n("Valuation Report")}</DialogTitle>
        </DialogHeader>
        <div className="flex h-full max-h-[450px] flex-wrap overflow-y-auto">
          <div className="cma-model_content">
            <span className="cma-model_content_header">
              {i18n("Confirm client details to add to your valuation report.")}
            </span>
            <div className="divider" />
            <ValuationSlider
              originalValuation={roundedOriginalValuationValue}
              onValuationChange={(value) => setValuation(value)}
            />
            <div className="divider" />
            <div className="cma-model_content_form">
              <div className="cma-model_content_form_row">
                <div className="cma-model_content_form_row_col">
                  <p className="mb-2 text-sm font-medium text-gray-700">
                    {i18n("Client first name")}*
                  </p>
                  <input
                    type="text"
                    onChange={(e) => setClientName(e.target.value)}
                    placeholder={i18n("Enter customer name") + "..."}
                    className="w-full rounded-lg border border-gray-300 p-2"
                  />
                </div>
                <div className="cma-model_content_form_row_col">
                  <p className="mb-2 text-sm font-medium text-gray-700">
                    {i18n("Client surname")}*
                  </p>
                  <input
                    type="text"
                    onChange={(e) => setClientSurname(e.target.value)}
                    placeholder={i18n("Enter customer surname") + "..."}
                    className="w-full rounded-lg border border-gray-300 p-2"
                  />
                </div>
              </div>
              <div className="cma-model_content_form_row">
                <div className="cma-model_content_form_row_col">
                  <p className="mb-2 text-sm font-medium text-gray-700">
                    {i18n("Property name")}*
                  </p>
                  <input
                    type="text"
                    onChange={(e) => setPropertyName(e.target.value)}
                    placeholder={i18n("Enter property name") + "..."}
                    className="w-full rounded-lg border border-gray-300 p-2"
                  />
                </div>
                <div className="cma-model_content_form_row_col">
                  <p className="mb-2 text-sm font-medium text-gray-700">
                    {i18n("Language")}
                  </p>
                  <Dropdown
                    className="standard-dropdown"
                    items={SUPPORTED_LANGUAGES.map((lang) => ({
                      label: lang.name,
                      value: lang.code,
                      onSelect: () => {
                        setLanguage(lang);
                      },
                    }))}
                    label={language.name}
                  />
                </div>
              </div>
              <div className="cma-model_content_form_row">
                <div className="cma-model_content_form_row_col">
                  <p className="mb-2 text-sm font-medium text-gray-700">
                    {i18n("Property image")}{" "}
                    {property.isCatastro
                      ? i18n("(will use satellite image if this is empty)")
                      : i18n("(will use default image if this is empty)")}
                  </p>
                  <input
                    type="file"
                    onChange={(e) => onCustomImageInputChange(e)}
                    className="w-full rounded-lg border border-gray-300 p-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <DialogFooter>
          <div className="flex w-full justify-end">
            <Button onClick={openPdf}>{i18n("Generate PDF")}</Button>
          </div>
        </DialogFooter>
        {showMergedPdf && (
          <CmaPDFProvider
            key={pdfKey}
            isInternalValuation={isInternalValuation}
            refcat={property.ref}
            clientName={clientName}
            clientSurname={clientSurname}
            propertyName={propertyName}
            language={language}
            property={property}
            valuationValue={currentValuation}
            propertyImageUrl={propertyImageUrl}
            district={district}
            municipality={municipality}
            province={province}
            agencyLogoUrl={agencyLogoUrl}
            upperRange={upperRange}
            lowerRange={lowerRange}
            studyAreas={studyAreas}
            rooms={rooms}
            bathrooms={bathrooms}
            features={features}
            locationUrl={locationUrl}
            personalDetails={{ ...personalDetails, email: user.email }}
            agencyDetails={agencyDetails}
            lang={language.code}
            customImage={customImage}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setIsPDFGenerated: require("../../actions/cmaActions").setIsPDFGenerated,
    },
    dispatch,
  );
};

export default connect(
  (state) => ({
    agencyDetails: state.user.userData.agencyDetails,
    personalDetails: state.user.userData.personalDetails,
    user: state.auth.user,
  }),
  mapDispatchToProps,
)(CmaModal);
