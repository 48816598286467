import fireApiRequest from "./core";

var FETCH_TEAM_PORTFOLIO =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/fetch_team_portfolio";
var SAVE_PROPERTY =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/save_property";

if (process.env.REACT_APP_NODE_ENV === "production") {
  FETCH_TEAM_PORTFOLIO =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/fetch_team_portfolio";
  SAVE_PROPERTY =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/save_property";
}

export async function fetchTeamPortfolioRequest() {
  let properties = null;

  try {
    let data = await fireApiRequest(FETCH_TEAM_PORTFOLIO, {
      method: "POST",
    });

    properties = data.properties;
  } catch (e) {
    console.log(e);
  }

  return properties;
}

export async function savePropertyRequest(data) {
  try {
    await fireApiRequest(SAVE_PROPERTY, {
      method: "POST",
      body: JSON.stringify(data),
    });
  } catch (e) {
    console.log(e);
  }
}
