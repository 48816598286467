import { createReducer } from "@reduxjs/toolkit";
import {
  addFilter,
  addInvisibleFilter,
  removeFilter,
  removeFilterGroup,
  removeInvisibleFilter,
  removeInvisibleFilterGroup,
  setBuildingType,
  setFilters,
  setInvisibleFilters,
  setSort,
  setSaleType,
} from "../actions/filterActions";
import { SORT_OPTIONS } from "../lib/filter/sort";
import { BUILDING_TYPE_FILTERS } from "lib/filter/filterTypes";
import store from "store";
import { onCreatePolygon } from "utils/map";

const initialState = {
  filters: [],
  invisibleFilters: [],
  sort: SORT_OPTIONS[0],
  buildingType: BUILDING_TYPE_FILTERS[0],
  saleType: "sale",
};

const filterReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setFilters, (state, action) => {
      state.filters = action.payload;
    })
    .addCase(addFilter, (state, action) => {
      // remove the filter with the existing filter id
      // then add the filter, so we don't add dupe filters
      let filters = state.filters.filter((f) => f.id != action.payload.id);
      state.filters = [].concat(filters, [action.payload]);
    })
    .addCase(removeFilter, (state, action) => {
      state.filters = state.filters.filter((f) => f.id != action.payload.id);
    })
    .addCase(removeFilterGroup, (state, action) => {
      state.filters = state.filters.filter((f) => f.type != action.payload);
    })
    .addCase(setInvisibleFilters, (state, action) => {
      state.invisibleFilters = action.payload;
    })
    .addCase(addInvisibleFilter, (state, action) => {
      // same as addFilter but for invisible filters
      let invisibleFilters = state.invisibleFilters.filter(
        (f) => f.id != action.payload.id,
      );
      state.invisibleFilters = [].concat(invisibleFilters, [action.payload]);
    })
    .addCase(removeInvisibleFilter, (state, action) => {
      state.invisibleFilters = state.invisibleFilters.filter(
        (f) => f.id != action.payload.id,
      );
    })
    .addCase(removeInvisibleFilterGroup, (state, action) => {
      state.invisibleFilters = state.invisibleFilters.filter(
        (f) => f.type != action.payload,
      );
    })
    .addCase(setSort, (state, action) => {
      state.sort = action.payload;
    })
    .addCase(setBuildingType, (state, action) => {
      // only persist private bank property filter when changing building type
      state.filters = state.filters.filter(
        (f) => f.path === "isPrivateBankProperty",
      );
      state.invisibleFilters = [];
      state.buildingType = action.payload;
    })
    .addCase(setSaleType, (state, action) => {
      state.saleType = action.payload;
      state.buildingType = BUILDING_TYPE_FILTERS[0];
    });
});

export default filterReducer;
