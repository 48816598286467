import { useEffect, useState } from "react";
import downChevron from "../../assets/core/down_chevron.svg";
import DropdownList from "./DropdownList";
import { i18n } from "i18n/localisation";

export default function (props) {
  const [isActive, setIsActive] = useState(false);

  let className = "dropdown";
  let dividerClassName = "divider";

  if (isActive) {
    className += " active";

    if (props.isInverted) {
      className += " inverted";
      dividerClassName += " inverted";
    }
  }

  const dropdownCanceller = () => {
    setIsActive(false);
  };

  const onDropdownClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsActive(!isActive);

    if (props.setActiveDropdownGroup) {
      props.setActiveDropdownGroup();
    }
  };

  useEffect(() => {
    if (props.noDropdownGrouping) {
      if (props.activeDropdownGroup != props.dropdownGroup) {
        setIsActive(false);
      }
    }

    document.body.addEventListener("click", dropdownCanceller);

    return () => {
      document.body.removeEventListener("click", dropdownCanceller);
    };
  });

  return (
    <div
      style={props.style}
      className={
        "dropdown-container" + (props.className ? ` ${props.className}` : "")
      }
    >
      <div onClick={onDropdownClick} className={className}>
        <div className="dropdown_label-container">
          {props.placeholder && (
            <span className="dropdown_placeholder">{props.placeholder}</span>
          )}
          <span>{i18n(props.label)}</span>
        </div>
        <img src={downChevron} />
      </div>
      {isActive && <div className={dividerClassName}></div>}
      {isActive && (
        <DropdownList
          selectedIds={props.selectedIds}
          noMaxHeight={props.noMaxHeight}
          isMulti={props.isMulti}
          onCancel={props.onCancel}
          cancelButton={props.cancelButton}
          defaultValue={props.defaultValue}
          isInverted={props.isInverted}
          items={props.items}
          setIsActive={setIsActive}
        />
      )}
    </div>
  );
}
