import { setIsPublished } from "actions/myPropertiesActions";
import { i18n } from "i18n/localisation";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../ui/Button/Button";
import Accordion from "./Accordion";
import { ACCORDION_SECTIONS } from "./uploadPropertyPageConfig";

const Checklist = () => {
  const [openedSections, setOpenedSections] = useState(new Set());
  const uploadPropertyData = useSelector((state) => ({
    photos: state.myProperties.photos,
    videos: state.myProperties.videos,
    floorPlans: state.myProperties.floorPlans,
    virtualTours: state.myProperties.virtualTours,
    price: state.myProperties.price,
    bedrooms: state.myProperties.bedrooms,
    bathrooms: state.myProperties.bathrooms,
    size: state.myProperties.size,
    features: state.myProperties.features,
    detailedType: state.myProperties.detailedType,
    description: state.myProperties.description,
    location: state.myProperties.location,
    isPublished: state.myProperties.isPublished,
  }));
  const dispatch = useDispatch();

  const calculateProgress = (sections, data) => {
    if (!data) return 0;

    const completedSections = sections.reduce(
      (acc, section) =>
        acc +
        section.steps?.every(
          (step) =>
            uploadPropertyData[step.key]?.length > 0 &&
            uploadPropertyData[step.key] !== null,
        ),
      0,
    );

    return (completedSections / sections.length) * 100;
  };

  const progressPercentage = useMemo(
    () => calculateProgress(ACCORDION_SECTIONS, uploadPropertyData),
    [uploadPropertyData],
  );

  const handleAccordionToggle = (sectionKey) => {
    setOpenedSections((prevState) => {
      const newState = new Set(prevState);
      if (newState.has(sectionKey)) {
        newState.delete(sectionKey);
      } else {
        newState.add(sectionKey);
      }
      return newState;
    });
  };

  return (
    <div className="checklist">
      <div className="checklist_header">
        <h2>{i18n("Complete your Property Listing")}</h2>
        <p>
          {i18n(
            "Upload your property details to list it and share either publicly or privately.",
          )}
        </p>
      </div>

      <div className="checklist_progress">
        <div className="checklist_progress_percentage">
          <p>{Math.round(progressPercentage)}%</p>
        </div>
        <div className="checklist_progress_bar">
          <div
            className="checklist_progress_bar_fill"
            style={{ width: `${progressPercentage}%` }}
          ></div>
        </div>
      </div>

      <div className="checklist_accordion-container">
        {ACCORDION_SECTIONS?.map(
          (section) =>
            section && (
              <Accordion
                key={section.key}
                section={section}
                isOpen={openedSections.has(section.key)}
                onToggle={() => handleAccordionToggle(section.key)}
              />
            ),
        )}
      </div>

      <div className="checklist_footer">
        <div className="align-center w-full p-[24px]">
          <p className="my-[8px] text-center text-[14px] text-[#717171]">
            {uploadPropertyData.isPublished
              ? i18n("Property is published")
              : i18n("Complete listing to publish")}
          </p>
          <Button
            className="w-full justify-center"
            disabled={
              progressPercentage < 100 && !uploadPropertyData.isPublished
            }
            onClick={() =>
              dispatch(
                uploadPropertyData.isPublished
                  ? setIsPublished(false)
                  : setIsPublished(true),
              )
            }
          >
            <span>
              {uploadPropertyData.isPublished
                ? i18n("Unpublish")
                : i18n("Publish")}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Checklist;
