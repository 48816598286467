import { createAction } from "@reduxjs/toolkit";
import { setFilteredProperties } from "./propertiesActions";
import { getFilteredPropertiesForState } from "lib/filter/filters";

const setFilters = createAction("filter/setFilters");
const addFilter = createAction("filter/addFilter");
const removeFilter = createAction("filter/removeFilter");
const removeFilterGroup = createAction("filter/removeFilterGroup");
const setInvisibleFilters = createAction("filter/setInvisibleFilters");
const addInvisibleFilter = createAction("filter/addInvisibleFilter");
const removeInvisibleFilter = createAction("filter/removeInvisibleFilter");
const removeInvisibleFilterGroup = createAction(
  "filter/removeInvisibleFilterGroup",
);
const setSort = createAction("filter/setSort");
const setBuildingType = createAction("filter/setBuildingType");
const setSaleType = createAction("filter/setSaleType");

export function setPropertyFilters(filters) {
  return (dispatch, getState) => {
    dispatch(setFilters(filters));
    dispatch(setFilteredProperties(getFilteredPropertiesForState()));
  };
}

export function addPropertyFilter(filter) {
  return (dispatch, getState) => {
    dispatch(addFilter(filter));
    dispatch(setFilteredProperties(getFilteredPropertiesForState()));
  };
}

export function removePropertyFilter(filter) {
  return (dispatch, getState) => {
    dispatch(removeFilter(filter));
    dispatch(setFilteredProperties(getFilteredPropertiesForState()));
  };
}

export function removePropertyFilterGroup(group) {
  return (dispatch, getState) => {
    dispatch(removeFilterGroup(group));
    dispatch(setFilteredProperties(getFilteredPropertiesForState()));
  };
}

export function setPropertyBuildingType(buildingType) {
  return (dispatch, getState) => {
    dispatch(setBuildingType(buildingType));
    dispatch(setFilteredProperties(getFilteredPropertiesForState()));
  };
}

export function setPropertySort(sort) {
  return (dispatch, getState) => {
    dispatch(setSort(sort));
    dispatch(setFilteredProperties(getFilteredPropertiesForState()));
  };
}

export {
  setFilters,
  addFilter,
  removeFilter,
  removeFilterGroup,
  setInvisibleFilters,
  addInvisibleFilter,
  removeInvisibleFilter,
  removeInvisibleFilterGroup,
  setSort,
  setBuildingType,
  setSaleType,
};
