import { connect } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import { i18n } from "i18n/localisation";
import Button from "components/ui/Button/Button";
import { FILTER_LABEL_MAPPINGS } from "lib/filter/filterTypes";
import { aiFiltersToHumanReadableMapping, capitalizeWord } from "utils/helpers";

function AiSearchHintDialog({ open, onClose, aiFilters }) {
  const filters = aiFiltersToHumanReadableMapping(aiFilters);

  const renderFilterValue = (filter) => {
    if (Array.isArray(filter.value)) {
      return filter.value.join(", ");
    }

    if (filter.value.from || filter.value.to) {
      if (filter.value.from && filter.value.from > 0) {
        return filter.value.from;
      } else if (filter.value.to && filter.value.to > 0) {
        return filter.value.to;
      }
    }

    return i18n(capitalizeWord(filter.value));
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        onClose();
      }}
    >
      <DialogContent className="w-full md:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{i18n("Location not found")}</DialogTitle>
        </DialogHeader>
        <div className="ml-4">
          <p className="mb-4 text-sm text-gray-500">
            {i18n("Your search filters")}:
          </p>

          {filters.map((filter) => (
            <div key={filter.id} className="py-1">
              <span className="font-medium capitalize">
                {filter.label}
                {filter.value !== true && ": "}
              </span>
              {filter.value !== true && (
                <span>{renderFilterValue(filter)}</span>
              )}
            </div>
          ))}
        </div>

        <div className="rounded-lg bg-blue-50 p-4">
          <p className="mb-4 text-blue-800">
            {i18n(
              "To apply these filters, please select a location on the map.",
            )}
            <br />
            {i18n(
              "For a smoother experience, specify a location in your next ai search.",
            )}
          </p>
          <p className="text-sm text-gray-500">
            {i18n("Example: 3 bed flat for under 500k in Marbella")}
          </p>
        </div>
        <DialogFooter>
          <div className="flex w-full justify-end">
            <Button onClick={() => onClose()} className="ml-4">
              {i18n("Got it")}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default connect((state) => ({
  filters: state.filters.filters,
}))(AiSearchHintDialog);
