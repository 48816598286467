import { getLang, i18n } from "i18n/localisation";
import {
  fetchCachedProperties,
  getLocalePropertyPrice,
  getPropertyDistrictByName,
  getPropertyGeoboundary,
  isPropertyFromDataSource,
  // getAverageTimeOnMarket,
  // getPropertyTimeOnMarket
} from "./properties";
import { getAllMapObjectsByType } from "db/mapStore";
import moment from "moment";
import { fetchAnalyticsRequest } from "api/analytics";

// Extract price calculations into separate utility functions
const calculatePricePerSqm = (price, size) => {
  if (!price || !size || isNaN(price) || isNaN(size) || price <= 0 || size <= 0)
    return null;
  return parseFloat(price) / parseFloat(size);
};

const formatPriceDifference = (property, currentPrice, averagePrice) => {
  const difference = currentPrice - averagePrice;
  const prefix = difference > 0 ? "+" : "";
  return {
    value: `${prefix}${getLocalePropertyPrice(property, Math.round(difference))}`,
    positive: difference > 0,
    negative: difference < 0,
  };
};

const formatPriceDifferencePercentage = (currentPrice, averagePrice) => {
  const percentageDiff = ((currentPrice - averagePrice) / averagePrice) * 100;
  const prefix = percentageDiff > 0 ? "+" : "";
  return {
    value: `${prefix}${Math.round(percentageDiff)}%`,
    positive: percentageDiff > 0,
    negative: percentageDiff < 0,
  };
};

// Extract property filtering logic
const filterPropertiesByTypology = (properties, referenceProperty) => {
  return properties.filter(
    (p) =>
      p.detailedType.typology === referenceProperty.detailedType.typology &&
      p.detailedType.subTypology ===
        referenceProperty.detailedType.subTypology &&
      p.buildingType === referenceProperty.buildingType,
  );
};

// New utility functions
const getRentalProperties = async (
  property,
  propertyDistrict,
  propertyZone,
  MIN_PROPERTIES_FOR_STATISTICS,
) => {
  const [districtRentals, zoneRentals] = await Promise.all([
    fetchCachedProperties(propertyDistrict?.id ?? propertyZone?.id, "rent"),
    fetchCachedProperties(propertyZone?.id, "rent"),
  ]);

  const useDistrict =
    districtRentals.length >= MIN_PROPERTIES_FOR_STATISTICS ||
    propertyDistrict?.type === "municipality";

  let rentalProperties = useDistrict ? districtRentals : zoneRentals;
  rentalProperties = rentalProperties.filter((p) => !p.rental_isShortTerm);
  return filterPropertiesByTypology(rentalProperties, property);
};

const calculateStatistics = (
  property,
  currentProperty,
  mean,
  averagePrice,
  averageRentalYield,
) => {
  if (property.saleType === "rent") {
    return calculateRentalStatistics(property, currentProperty, averagePrice);
  }

  return calculateSaleStatistics(
    property,
    currentProperty,
    mean,
    averagePrice,
    averageRentalYield,
  );
};

const calculateSaleStatistics = (
  property,
  currentProperty,
  mean,
  averagePrice,
  averageRentalYield,
) => {
  const statistics = [
    {
      label: i18n("Average price/m²:"),
      value: getLocalePropertyPrice(property, Math.round(mean)),
    },
    {
      label: i18n("Property price/m²:"),
      value: getLocalePropertyPrice(
        property,
        Math.round(parseFloat(property.price) / parseFloat(property.size)),
      ),
    },
    {
      ...formatPriceDifference(
        property,
        parseFloat(property.price) / parseFloat(property.size),
        mean,
      ),
      label: i18n("Price/m² difference:"),
    },
    {
      ...formatPriceDifferencePercentage(
        parseFloat(property.price) / parseFloat(property.size),
        mean,
      ),
      label: i18n("Price/m² difference %:"),
    },
    {
      label: i18n("Average price:"),
      value: getLocalePropertyPrice(property, Math.round(averagePrice)),
    },
    {
      ...formatPriceDifference(
        property,
        currentProperty.originalPrice,
        averagePrice,
      ),
      label: i18n("Price difference:"),
    },
  ];

  if (averageRentalYield) {
    statistics.push({
      label: i18n("Average long term rental yield"),
      value: `${getLocalePropertyPrice(property, Math.round(averageRentalYield))} / ${i18n("month")}`,
    });
  }

  return statistics;
};

const calculateRentalStatistics = (property, currentProperty, averagePrice) => {
  const suffix = property.rental_isShortTerm
    ? i18n(" / day")
    : i18n(" / month");

  return [
    {
      label: i18n("Average price:"),
      value:
        getLocalePropertyPrice(property, Math.round(averagePrice)) + suffix,
    },
    {
      label: i18n("Property price:"),
      value: getLocalePropertyPrice(property, property.price) + suffix,
    },
    {
      ...formatPriceDifference(
        property,
        currentProperty.originalPrice,
        averagePrice,
      ),
      label: i18n("Price difference:"),
    },
    {
      ...formatPriceDifferencePercentage(
        currentProperty.originalPrice,
        averagePrice,
      ),
      label: i18n("Price difference %:"),
    },
  ];
};

const calculateBins = (prices, currentProperty, property, binCount = 16) => {
  const minPrice = Math.min(...prices);
  const maxPrice = Math.max(...prices);
  const binSize = (maxPrice - minPrice) / binCount;

  return Array.from({ length: binCount }, (_, i) => {
    const lowerBound = minPrice + i * binSize;
    const upperBound = i === binCount - 1 ? maxPrice : lowerBound + binSize;

    const count = prices.filter((p) =>
      i === 0
        ? p >= lowerBound && p <= upperBound
        : i === binCount - 1
          ? p >= lowerBound && p <= upperBound
          : p >= lowerBound && p < upperBound,
    ).length;

    const isCurrentPropertyBin =
      property.saleType === "rent"
        ? currentProperty.originalPrice >= lowerBound &&
          currentProperty.originalPrice < upperBound
        : currentProperty.price >= lowerBound &&
          currentProperty.price <= upperBound;

    return {
      label: `${getLocalePropertyPrice(property, Math.round(lowerBound))} - ${getLocalePropertyPrice(property, Math.round(upperBound))}`,
      count,
      isCurrentPropertyBin,
    };
  });
};

// Main function refactored
export async function getStatsAndChartData(
  property,
  propertyDistrict,
  propertyZone,
) {
  const MIN_PROPERTIES_FOR_STATISTICS = 5;

  if (!propertyDistrict && !propertyZone) return null;

  // Fetch and filter properties
  const [districtProperties, zoneProperties] = await Promise.all([
    fetchCachedProperties(
      propertyDistrict?.id ?? propertyZone?.id,
      property.saleType ?? "sale",
    ),
    fetchCachedProperties(propertyZone?.id, property.saleType ?? "sale"),
  ]);

  const filteredDistrictProperties = filterPropertiesByTypology(
    districtProperties,
    property,
  );
  const filteredZoneProperties = filterPropertiesByTypology(
    zoneProperties,
    property,
  );

  // Determine which properties to use based on count
  const useDistrict =
    filteredDistrictProperties.length >= MIN_PROPERTIES_FOR_STATISTICS ||
    propertyDistrict?.type === "municipality";

  const properties = useDistrict
    ? filteredDistrictProperties
    : filteredZoneProperties;
  const resolvedDistrict = useDistrict ? propertyDistrict : propertyZone;

  if (properties.length < MIN_PROPERTIES_FOR_STATISTICS) return null;

  // Calculate prices
  const pricesPerSqm = isPropertyFromDataSource(property, "airbnb")
    ? properties.map((p) => ({
        id: p.id,
        price: parseFloat(p.price),
        originalPrice: parseFloat(p.price),
        size: 1,
        isCurrentProperty: property.id === p.id,
      }))
    : properties
        .map((p) => ({
          id: p.id,
          price: calculatePricePerSqm(p.price, p.size),
          originalPrice: parseFloat(p.price),
          size: parseFloat(p.size),
          isCurrentProperty: property.id === p.id,
        }))
        .filter((p) => p.price !== null);

  // Get rental properties if needed
  let rentalProperties = [];
  if (property.saleType !== "rent") {
    rentalProperties = await getRentalProperties(
      property,
      propertyDistrict,
      propertyZone,
      MIN_PROPERTIES_FOR_STATISTICS,
    );
  }
  const averageRentalYield =
    rentalProperties.length >= 5
      ? rentalProperties.reduce((a, b) => a + parseFloat(b.price), 0) /
        rentalProperties.length
      : null;

  // Calculate prices and statistics
  const prices =
    property.saleType === "rent"
      ? pricesPerSqm.map((p) => p.originalPrice)
      : pricesPerSqm.map((p) => p.price);

  const mean = prices.reduce((a, b) => a + b, 0) / prices.length;
  const averagePrice =
    pricesPerSqm.reduce((a, b) => a + b.originalPrice, 0) / pricesPerSqm.length;
  const currentProperty = pricesPerSqm.find((p) => p.isCurrentProperty);

  if (!currentProperty) return null;

  const bins = calculateBins(prices, currentProperty, property);
  const statistics = calculateStatistics(
    property,
    currentProperty,
    mean,
    averagePrice,
    averageRentalYield,
  );

  return {
    chartData: {
      labels: bins.map((bin) => bin.label),
      datasets: [
        {
          label: i18n("Number of Properties"),
          data: bins.map((bin) => bin.count),
          backgroundColor: bins.map((bin) =>
            bin.isCurrentPropertyBin ? "#ee3943" : "#8884",
          ),
          borderColor: bins.map((bin) =>
            bin.isCurrentPropertyBin ? "#ee3943" : "#888",
          ),
          borderWidth: 1,
        },
      ],
    },
    options: {
      plugins: {
        legend: {
          display: true,
          position: "top",
          labels: {
            generateLabels: () => [
              {
                text: i18n("Other Properties"),
                fillStyle: "#8884",
                strokeStyle: "#888",
                lineWidth: 1,
              },
              {
                text: i18n("Current Property"),
                fillStyle: "#ee3943",
                strokeStyle: "#ee3943",
                lineWidth: 1,
              },
            ],
          },
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: i18n("Price Range"),
            font: { size: 14 },
          },
        },
        y: {
          ticks: { stepSize: 1 },
          title: {
            display: true,
            text: i18n("Number of Properties"),
            font: { size: 14 },
          },
        },
      },
    },
    statistics,
    resolvedDistrict,
  };
}

// Historic data function remains largely the same
export async function getAveragePricePerSqmHistoricChartData(areaId) {
  if (!areaId) return null;

  const results = await fetchAnalyticsRequest("av_price_per_sqm", areaId);
  if (!results?.length) return null;

  const sortedResults = results.sort(
    (a, b) => parseFloat(a.time_taken_from) - parseFloat(b.time_taken_from),
  );

  return {
    chartData: {
      labels: sortedResults.map((r) =>
        moment.unix(parseFloat(r.time_taken_from)).format("YYYY"),
      ),
      datasets: [
        {
          label: i18n("Average Price per m²"),
          data: sortedResults.map((r) => parseFloat(r.value)),
          fill: false,
          backgroundColor: "#ee3943",
          borderColor: "#ee3943",
          borderWidth: 2,
          pointRadius: 0,
        },
      ],
    },
    options: {
      scales: {
        x: { title: { display: true, text: i18n("Time") } },
        y: { title: { display: true, text: i18n("Price per m²") } },
      },
    },
  };
}
