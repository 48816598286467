import Navbar from "components/navbar/Navbar";
import LoadingSpinner from "components/ui/LoadingSpinner/LoadingSpinner";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  setBathrooms,
  setBedrooms,
  setCatastroReference,
  setCoordinates,
  setDescription,
  setDetailedType,
  setFeatures,
  setFloorPlans,
  setLocation,
  setMunicipality,
  setPhotos,
  setPrice,
  setPropertyId,
  setProvince,
  setSize,
  setTitle,
  setVideos,
  setVirtualTours,
} from "../../actions/myPropertiesActions";
import { fetchPropertyRequest } from "../../api/properties";
import Checklist from "./Checklist";
import Description from "./Description";
import Location from "./Location";
import MediaUpload from "./mediaUpload/MediaUpload";
import MyPropertiesHeader from "./MyPropertiesHeader";

const UploadPage = ({ onUnlikeProperty, setIsReportModalOpen }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { propertyId } = useParams();
  const dispatch = useDispatch();
  const catastro = useSelector((state) => state.myProperties.catastroProperty);
  const propertyType = useSelector((state) => state.myProperties.propertyType);
  dispatch(setPropertyId(propertyId));

  useEffect(() => {
    setIsLoading(true);
    if (catastro) {
      dispatch(setTitle(`${propertyType.label} in ${catastro.municipality}`));
      dispatch(
        setCoordinates({
          latitude: catastro.latitude,
          longitude: catastro.longitude,
        }),
      );
      setIsLoading(false);
      return;
    }

    const fetchTeamPortfolio = async () => {
      try {
        const response = await fetchPropertyRequest(propertyId);
        const property = response.property;

        if (property) {
          dispatch(setTitle(property.title));
          dispatch(setPhotos(property.multimedia.images));
          dispatch(setVideos(property.multimedia.videos));
          dispatch(setFloorPlans(property.multimedia.plans));
          dispatch(setVirtualTours(property.multimedia.virtualTour));
          dispatch(setPrice(property.price));
          dispatch(setDetailedType(property.detailedType.typology));
          dispatch(setBedrooms(property.rooms));
          dispatch(setBathrooms(property.bathrooms));
          dispatch(setSize(property.size));
          dispatch(setFeatures(property.features));
          dispatch(setDescription(property.description));
          dispatch(setLocation(property.address));
          dispatch(setMunicipality(property.municipality));
          dispatch(setProvince(property.province));
          dispatch(setCatastroReference(property.refcat));
          dispatch(
            setCoordinates({
              latitude: property.latitude,
              longitude: property.longitude,
            }),
          );
        }
      } catch (error) {
        console.error("Error fetching user properties", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTeamPortfolio();
  }, [propertyId, dispatch]);

  return (
    <div className="upload-page">
      <Navbar />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <MyPropertiesHeader />
          <div className="max-w-vw ml-[100px] flex gap-[18px]">
            <div className="flex w-full flex-col gap-[18px]">
              <MediaUpload />
              <Description />
              <Location />
            </div>
            <div className="mr-[82px] max-w-[389px]">
              <Checklist />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UploadPage;
