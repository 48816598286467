const IconComponent = ({ icon, color, parameter }) => {
  const Icon = icon;
  const style = {
    [parameter]: color,
  };

  return <Icon style={style} />;
};

export default IconComponent;
