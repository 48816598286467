import HouseAnimation from "components/animations/HouseAnimation";
import { useEffect, useState } from "react";
import { connect } from "react-redux";

let progressInterval = null;

const GlobalLoader = function (props) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!progressInterval && props.globalLoading) {
      progressInterval = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 99) {
            clearInterval(progressInterval);
            return prevProgress;
          }

          return prevProgress + 0.1;
        });
      }, 1);
    } else if (progressInterval && !props.globalLoading) {
      setTimeout(() => {
        setProgress(() => 0);
        clearInterval(progressInterval);
        progressInterval = null;
      }, 100);
    }
  }, [props.globalLoading]);

  return (
    <div
      className={
        "global-loader" + (props.globalLoading ? " global-loader-fade-in" : "")
      }
    >
      <div className="global-loader_container">
        <span className="global-loader_message">
          {props.globalLoadingMessage}
        </span>
        <div className="global-loader_center">
          <HouseAnimation />
        </div>
      </div>
    </div>
  );
};

export default connect((state) => ({
  globalLoading: state.misc.globalLoading,
  globalLoadingMessage: state.misc.globalLoadingMessage,
}))(GlobalLoader);
